






















import {Component, Prop, Watch, Vue} from "vue-property-decorator";
import { setDefaults } from "./scrollTo";
import scrollTo from "./scrollTo";

@Component({})
export default class TDSSlider extends Vue {
    @Prop({type: Number, required: true}) numberOfSlides!: number;
    @Prop({type: String, default: ""}) indicators!: string;
    @Prop({type: Boolean, default: false}) showControls!: boolean;
    @Prop({type: Boolean, default: false}) darkMode!: boolean;
    @Prop({type: Number, default: 0}) autoScrollTime!: number;
    //iOS bugfix
    @Prop({type: Boolean, default: false}) stopOverflowScrolling!: boolean;

    private sliderID: string = "sl" + Math.random().toString(36).substr(2, 6);
    private shouldSnap: boolean = true;
    private scrollActive: boolean = false;
    private controlsShown: boolean = false;
    private activeSlide: number = 1;
    private autoScrollLoop: any = undefined;

    mounted() {
        (this.$refs[this.sliderID] as HTMLDivElement).addEventListener("scroll", this.handleScrolling);
        setDefaults(
            {
                container: "#" + this.sliderID,
                duration: 700,
                easing: "ease",
                offset: 0,
                force: true,
                cancelable: true,
                onStart: () => { this.shouldSnap = false; this.scrollActive = true },
                onDone: () => { this.scrollActive = false },
                onCancel: () => { this.scrollActive = false },
                x: true,
                y: false
            }
        );
        this.startLoop();
    }

    beforeDestroy() {
        (this.$refs[this.sliderID] as HTMLDivElement).removeEventListener("scroll", this.handleScrolling);
    }

    @Watch("autoScrollTime")
    autoScrollTimeChanged() {
        this.stopLoop();
        this.startLoop();
    }

    private handleScrolling() {
        // @ts-ignore
        const offsetLeft: number = event.srcElement?.offsetLeft || 0;
        // @ts-ignore
        const scrollLeft: number = event.srcElement?.scrollLeft || 0;
        let slideNumber: number = 1;
        for(const key in this.$refs) {
            if(key === this.sliderID || !this.$refs[key]) continue;
            else {
                // @ts-ignore
                if(this.$refs[key][0].offsetLeft <= scrollLeft + offsetLeft + this.$refs[key][0].offsetWidth/2) slideNumber = parseInt(key.substr(key.indexOf("-")+1));
            }
        }
        if(this.activeSlide !== slideNumber) this.$emit("slideChanged", slideNumber);
        this.activeSlide = slideNumber;
    }

    private goToSlide(key: string) {
        // @ts-ignore
        if(!this.scrollActive) scrollTo("#" + this.sliderID + "-" + key);
    }

    private autoScroll() {
        if(!this.autoScrollTime || this.numberOfSlides <= 1) return;
        const index: number = this.activeSlide < this.numberOfSlides ? this.activeSlide + 1 : 1;
        this.goToSlide("slide-" + index);
        this.autoScrollLoop = setTimeout(this.autoScroll, this.autoScrollTime*1000);
    }

    private startLoop() {
        if(this.autoScrollTime) this.autoScrollLoop = setTimeout(this.autoScroll, this.autoScrollTime*1000);
    }

    private stopLoop() {
        if(this.autoScrollLoop) clearTimeout(this.autoScrollLoop);
    }
}
