import Vue from "vue";
import store from "../store";
import VueRouter, {Route, RouteConfig} from "vue-router";
import DefaultLayout from "../components/layouts/DefaultLayout.vue";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
    {
        path: "/swap",
        component: DefaultLayout,
        children: [
            {
                name: "Swap Coins",
                path: "",
                meta: {headerHeight: "149px"},
                component: () => import(/* webpackChunkName: "swap-coins" */ "../components/pages/SwapCoins.vue")
            }
        ]
    },
    {
        path: "/send",
        component: DefaultLayout,
        children: [
            {
                name: "Send Coins",
                path: "",
                meta: {headerHeight: "149px"},
                component: () => import(/* webpackChunkName: "swap-coins" */ "../components/pages/SendCoins.vue")
            }]
    },
    {
        path: "/pools",
        component: DefaultLayout,
        children: [
            {
                name: "Liquidity Pools",
                path: "",
                meta: {headerHeight: "149px"},
                component: () => import(/* webpackChunkName: "liquidity-pools" */ "../components/pages/LiquidityPools.vue")
            }
        ]
    },
    {
        path: "*",
        component: DefaultLayout,
        children: [
            {
                name: "Onboarding",
                path: "",
                meta: {headerHeight: "100%", hideNav: true},
                component: () => import(/* webpackChunkName: "onboarding" */ "../components/pages/Onboarding.vue")
            }
        ]
    }
];

const router = new VueRouter({
    routes
});

router.afterEach((to: Route, from: Route) => {
    store.commit("SET_PREVIOUS_VIEW", "");
    store.commit("SET_HEADER_HEIGHT", to.meta?.headerHeight ?? "213px");
    document.body.style.position = "relative";
    document.title = process.env.VUE_APP_THEME_TITLE || "";
});

export default router;
