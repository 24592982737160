import Web3 from "web3";
import BigNumber from "bignumber.js";
import WalletConnect from "@walletconnect/client";
import QRCodeModal from "@walletconnect/qrcode-modal";

const web3 = new Web3(Web3.givenProvider);
const contractAddress = process.env.VUE_APP_ETH_NETWORK === "mainnet"? "0x3597bfd533a99c9aa083587b074434e61eb0a258":"0xa51f5e00b3a454Ba56c4Add590D5CFEb2D197ae0";
const transferABI = [
    {
        "constant": false,
        "inputs": [
            {
                "name": "_to",
                "type": "address"
            },
            {
                "name": "_value",
                "type": "uint256"
            }
        ],
        "name": "transfer",
        "outputs": [
            {
                "name": "",
                "type": "bool"
            }
        ],
        "type": "function"
    }
];
// @ts-ignore
const contract = new web3.eth.Contract(transferABI, contractAddress);

const mobileWalletConnector: WalletConnect = new WalletConnect({
    bridge: "https://bridge.walletconnect.org",
    qrcodeModal: QRCodeModal
});
export async function transferDENT(from: string, to: string, value: string) {
    return await contract.methods.transfer(to, "0x" + new BigNumber(value).toString(16)).send({from: from, gas: 100000});
}
export async function transferDENTMobile(from: string, to: string, value: string) {
    const data = contract.methods.transfer(to, "0x" + new BigNumber(value).toString(16)).encodeABI();
    const tx = await mobileWalletConnector.sendTransaction({
        to: contractAddress,
        data: data,
        from: from,
        gas: 100000
    });
}

