import { render, staticRenderFns } from "./LiquidityPools.vue?vue&type=template&id=0269ddaf&scoped=true&"
import script from "./LiquidityPools.vue?vue&type=script&lang=ts&"
export * from "./LiquidityPools.vue?vue&type=script&lang=ts&"
import style0 from "./LiquidityPools.vue?vue&type=style&index=0&id=0269ddaf&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0269ddaf",
  null
  
)

export default component.exports