import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export interface DefaultState {
    auth: string;
    apiVersion: string;
    headerHeight: string;
    isMobile: boolean;
    previousView: string;
}

export interface VuexActionContext<T> {
    commit: (mutationId: string, data: T) => void;
}

export default new Vuex.Store({
    state: {
        auth: "",
        apiVersion: "",
        headerHeight: "213px",
        isMobile: false,
        previousView: ""
    },
    mutations: {
        SET_AUTH(state: DefaultState, auth: string) {
            state.auth = auth;
        },
        SET_HEADER_HEIGHT(state: DefaultState, headerHeight: string) {
            state.headerHeight = headerHeight;
        },
        SET_IS_MOBILE(state: DefaultState, isMobile: boolean) {
            state.isMobile = isMobile;
        },
        SET_PREVIOUS_VIEW(state: DefaultState, previousView: string) {
            state.previousView = previousView;
        }
    },
    actions: {}
});
