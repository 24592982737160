





























































































import {Component, Vue, Watch} from "vue-property-decorator";
import TDSButton from "@/components/common/TDSButton.vue";
import InputField from "@/components/common/InputField.vue";
import TDSModal from "@/components/common/TDSModal/TDSModal.vue";
import TDSSpinner from "@/components/common/TDSSpinner.vue";
import {transferDENT, transferDENTMobile} from "@/util/transferDENT";
import WalletConnect from "@walletconnect/client";
import QRCodeModal from "@walletconnect/qrcode-modal";
import BigNumber from "bignumber.js";
import CurrencyInputfield from "@/components/common/CurrencyInputfield.vue";

interface Constrain {
    regex: RegExp;
    text: string;
    valid: boolean;
}

@Component({
    components: {CurrencyInputfield, TDSModal, InputField, TDSButton, TDSSpinner}
})
export default class SendCoins extends Vue {
    private amount: BigNumber = new BigNumber(0);
    private walletModalOpen: boolean = false;
    private isUsingDentAccountWallet: boolean = false;
    private isMobileDevice: boolean= false;
    private modalView: string = "default";
    private walletAddress: string = "";
    private senderAddress: string = "";
    private manualAddress: string = "";
    private isLoading: boolean = false;
    private modalInputValid: boolean = false;
    private constrains: Array<Constrain> = [
        {
            regex: new RegExp(/^0x.*$/),
            text: this.$t("send.modal.manualAddress.constrain1") as string,
            valid: false
        },
        {
            regex: new RegExp(/^.{42}$/),
            text: this.$t("send.modal.manualAddress.constrain2") as string,
            valid: false
        },
        {
            regex: new RegExp(/^[0-9a-zA-Z]+$/),
            text: this.$t("send.modal.manualAddress.constrain3") as string,
            valid: false
        }
    ]
    private mobileWalletConnector: WalletConnect = new WalletConnect({
        bridge: "https://bridge.walletconnect.org", // Required
        qrcodeModal: QRCodeModal
    });
    private decimalPoint: number | undefined = 2;

    get inputValid(): boolean {
        // return !!(this.senderAddress && this.walletAddress && !isNaN(Number(this.amount)) && Number(this.amount) > 0);
        return !!(this.senderAddress && this.walletAddress && !this.amount.isNaN() && this.amount.isGreaterThan(0));
    }

    get isMobile(): boolean {
        return this.$store.state.isMobile;
    }

    get selectOptionText(): string {
        if (!this.walletAddress) return "Choose a Wallet";
        if (this.isUsingDentAccountWallet) return "DENT Exchange Account";
        return this.walletAddress.substr(0, 10) + "..." + this.walletAddress.substr(32, 10);
    }

    get selectOptionIconClass(): string {
        if (!this.walletAddress) return "no-wallet";
        if (this.isUsingDentAccountWallet) return "dent-wallet";
        return "manual-wallet";
    }

    @Watch("manualAddress")
    manualAddressChanged(value: string) {
        let inputValid = true;
        this.constrains.forEach((constrain: Constrain) => {
            if(!constrain.regex.test(value)) {
                constrain.valid = false;
                inputValid = false;
            } else {
                constrain.valid = true;
            }
        });
        this.modalInputValid = inputValid;
    }

    created() {
        this.walletAddress = (this.$route.query.address as string) ?? "";
        if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)){
            this.isMobileDevice = true;
        }
        this.retrieveSenderAddress();
        if(this.$route.query.address) {
            this.walletAddress = (this.$route.query.address as string) ?? "";
            this.isUsingDentAccountWallet = true;
            this.removeWalletAddressFromUrl();
        }
    }

    //  The user is sending DENTs from a sender wallet to somewhere else.
    //  Here we are trying to get the wallet address of the sender from the connected
    //  MetaMask account. So the user needs to be connected...
    async retrieveSenderAddress() {
        this.isLoading = true;
        let accounts: string[];
        if(!this.isMobileDevice) {
            accounts = await (window as any).web3.eth.getAccounts();
            if (accounts.length !== 1) {
                this.$toastr.error("Could not receive your wallet address from MetaMask. Please check the connection and try again.");
                return;
            }
        }
        else {
            accounts = this.mobileWalletConnector.accounts;
        }
        this.senderAddress = accounts[0];
        this.isLoading = false;
    }

    private clearAddress() {
        this.manualAddress = "";
        this.walletAddress = "";
        this.modalView = "default";
    }

    private openModal() {
        if (this.walletAddress && !this.isUsingDentAccountWallet) {
            this.modalView = "manualAddress";
            this.manualAddress = this.walletAddress;
            this.modalInputValid = true;
        }
        this.walletModalOpen = true;
    }

    private closeModal() {
        this.walletModalOpen = false;
        this.modalView = "default";
        this.modalInputValid = false;
    }

    private removeWalletAddressFromUrl() {
        window.location.hash = window.location.hash.split("?")[0];
    }

    //  We are retrieving the wallet address of the recipient from the DENT Exchange
    //  Just call the link below with a redirect URL in the query to receive the deposit address
    //  for the DENT account. The user might need to login first on the DENT Exchange. But that is handled
    //  on the DENT Exchange itself :)
    private getDENTWalletAddress() {
        this.modalView = "loadingAddress";
        const link = process.env.VUE_APP_DENT_EXCHANGE_LINK + "#/assets/deposits/DENT/address?redirect_url=" + encodeURIComponent(window.location.href);
        setTimeout(() => {
            window.open(link, "_self");
        }, 1500);
    }

    private sendToManualAddress() {
        this.modalView = "manualAddress";
    }

    private setManualAddress() {
        this.walletAddress = this.manualAddress;
        this.isUsingDentAccountWallet = false;
        this.closeModal();
    }

    private async send() {
        this.isLoading = true;
        try {
            if (!this.isMobileDevice) {

                await transferDENT(this.senderAddress, this.walletAddress, this.amount.multipliedBy(Math.pow(10, 8)).toString());
                await this.$toastr.success("Transaction was successful.");
            }
            else {
                await transferDENTMobile(this.senderAddress, this.walletAddress, this.amount.multipliedBy(Math.pow(10, 8)).toString());
                await this.$toastr.success("Transaction was successful.");

            }
            this.amount = new BigNumber(0);
            this.walletAddress = "";
            this.removeWalletAddressFromUrl();
        } catch (e) {
            console.error("[SendCoins] Error on sending DENTs: ", e);
            if (e.code !== 4001) { // 4001 cancel
                this.$toastr.error("An unexpected error occurred. Please try again later.");
            }
        }
        this.isLoading = false;
    }
}
