































import {Component, Vue} from "vue-property-decorator";
import TDSSlider from "@/components/common/TDSSlider/TDSSlider.vue";
import TDSButton from "@/components/common/TDSButton.vue";
@Component({
    components: {TDSButton, TDSSlider}
})
export default class Onboarding extends Vue {
    get isMobile() {
        return this.$store.state.isMobile;
    }
}
