












































































import TDSButton from "@/components/common/TDSButton.vue";
import TDSModal from "@/components/common/TDSModal/TDSModal.vue";
import InputField from "@/components/common/InputField.vue";
import BigNumber from "bignumber.js";
import {formatCurrencyAmount} from "@/util/formatCurrencyAmount.ts";
import {Component, Prop, Vue, Watch} from "vue-property-decorator";
import TDSSpinner from "@/components/common/TDSSpinner.vue";
import {ERC20WalletToken} from "@/interfaces/entities/ERC20WalletToken";
import CurrencyInputfield from "@/components/common/CurrencyInputfield.vue";


@Component({
    components: {CurrencyInputfield, TDSButton, TDSModal, InputField, TDSSpinner}
})
export default class TokenCard extends Vue {
    @Prop({type: String}) readonly direction: string | undefined;
    @Prop({type: Number}) readonly networkVersion: number | undefined;
    @Prop({type: String}) readonly accountAddress: string | undefined;
    @Prop({type: Boolean}) readonly isMobile: boolean | undefined;
    @Prop({type: Boolean}) readonly isBusy: boolean | undefined;
    @Prop({type: BigNumber}) readonly amount: BigNumber | undefined;
    @Prop({type: Array}) readonly userERC20Tokens: Array<ERC20WalletToken> | undefined;
    @Prop({type: Array}) readonly tokens: Array<any> | undefined;

    private showModal: boolean = false;
    private isTokenSelected: boolean = false;
    private selectedToken: string = "";
    private amountEntered: BigNumber = new BigNumber(0);

    amountChanged(amount: BigNumber) {
        this.$emit("amountChanged", amount);
    }

    @Watch("amount")
    amountFromParentChanged() {
        if (this.amount !== undefined) {
            this.amountEntered = this.amount;
        }
    }

    created() {
        this.amountEntered = this.amount || new BigNumber(0);
    }

    get maxDp() {
        return this.selToken?.decimals || 0;
    }

    get selToken() {
        return this.tokens?.find((a: any) => a.symbol === this.selectedToken);
    }

    private getToken(tokenSymbol: string) {
        // eslint-disable-next-line @typescript-eslint/typedef
        return this.userERC20Tokens?.find(token => token.symbol === tokenSymbol);
    }

    private getDirection() {
        if (this.direction === "TO") {
            return "receive";
        } else {
            return "swap";
        }
    }

    getTokenAmount(tokenName: string) {
        // eslint-disable-next-line @typescript-eslint/typedef
        const token = this.userERC20Tokens?.find(token => token.name === tokenName);
        if (token === undefined) {
            return 0;
        } else {
            return formatCurrencyAmount(token.amount, 4).toString();
        }

    }

    selectToken(tokenSymbol: string) {
        this.selectedToken = tokenSymbol;
        this.showModal = false;
        this.isTokenSelected = true;
        this.$emit("tokenSelected", tokenSymbol);
    }

    setInputMax(){
        if(this.selectedToken) {
            // eslint-disable-next-line @typescript-eslint/typedef
            const result = this.userERC20Tokens?.find(token => {
                return token.symbol === this.selectedToken;
            });
            if(result?.amount.toString() === "0" || result == null) {
                return this.$toastr.error("Your account has no available balance for " + this.selectedToken + ".");
            }
            this.amountEntered = result?.amount;
            this.amountChanged(new BigNumber(this.amountEntered));
        }
    }
}
