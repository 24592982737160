























































import {Component, Prop, Vue, Watch} from "vue-property-decorator";
import DefaultFooter from "@/components/partials/DefaultFooter.vue";
import MobileMenuButton from "@/components/partials/Navigation/MobileMenuButton.vue";
import TDSSpinner from "@/components/common/TDSSpinner.vue";
import TDSButton from "@/components/common/TDSButton.vue";

@Component({
    components: {TDSButton, TDSSpinner, MobileMenuButton, DefaultFooter}
})
export default class DefaultNavigationBar extends Vue {
    @Prop({type: Boolean, default: false}) sidebar?: boolean;
    @Prop({type: Boolean, default: false}) bottombar?: boolean;
    private mobileNavOpen: boolean = false;
    private isLoading: boolean = false;
    private dentExchangeLink: string = process.env.VUE_APP_DENT_EXCHANGE_LINK ?? "";

    get isMobile(): boolean {
        return this.$store.state.isMobile;
    }

    get previousView(): boolean {
        return this.$store.state.previousView;
    }

    get fullscreen() {
        return this.$route.meta.headerHeight === "100%" && this.isMobile;
    }

    @Watch("$route")
    routeChanged() {
        if(this.isMobile) this.mobileNavOpen = false;
    }

    @Watch("mobileNavOpen")
    mobileNavChanged(newVal: boolean) {
        if (newVal) document.body.style.position = "fixed";
        else document.body.style.position = "relative";
    }

    @Watch("isMobile")
    mobileChanged(newVal: boolean) {
        if (!newVal) this.mobileNavOpen = false;
    }
}
