import Web3 from "web3";
import Eth from "web3-eth";
import ERC20_ABI from "@/util/ERC20TokenContract.json";

// @ts-ignore
const eth = new Eth(Eth.givenProvider);
const web3 = new Web3(Web3.givenProvider);
const approveABI = [
    {
        "constant": false,
        "inputs": [
            {
                "name": "_spender",
                "type": "address"
            },
            {
                "name": "_value",
                "type": "uint256"
            }
        ],
        "name": "approve",
        "outputs": [
            {
                "name": "",
                "type": "bool"
            }
        ],
        "payable": false,
        "stateMutability": "nonpayable",
        "type": "function"
    }
];

export async function approve(from: string, addressToApprove: string, contractAddress: string) {
    // @ts-ignore
    const contract = new web3.eth.Contract(approveABI, contractAddress);
    return await contract.methods.approve(addressToApprove, "0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff" ).send({from: from}); // maximum uint256 value
}

