import Vue, {VNode, VueConstructor} from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import i18n from "./i18n";
import {Toastr} from "./plugins/Toastr/Toastr";
import {Authenticator} from "./plugins/Authenticator";
import VueScrollTo from "vue-scrollto";
import TDSDate from "@/util/TDSDate";
import {Regexes} from "@/plugins/Regexes";

require("dotenv").config();

Vue.config.productionTip = false;

Vue.use(Toastr);
Vue.use(Authenticator);
Vue.use(VueScrollTo);
Vue.use(Regexes);

// TODO: Get currency info from API and make format based on that...

Vue.filter("cryptoFormat", (value: number | string, maximumFractionDigits: number, code: string) => {
    if (typeof value === "string") value = parseFloat(value);
    return new Intl
        .NumberFormat("en", {maximumFractionDigits})
        .format(value) + " " + code;
});

Vue.filter("fiatFormat", (value: number | string, currencySign: boolean = false) => {
    if (typeof value === "string") value = parseFloat(value);
    return new Intl.NumberFormat("en", {
        style: "currency",
        currency: "USD",
        currencyDisplay: currencySign ? "symbol" : "code"
    }).format(value);
});

Vue.filter("percentageFormat", (value: number | string) => {
    if (typeof value === "string") value = Number(value);
    return (value > 0 ? "+" : "") + value.toFixed(2) + "%";
});

Vue.filter("timeFormat", (value: number | string, format: string) => {
    if (typeof value !== "number") return value;
    switch (format) {
        case "d": // @ts-ignore
            return Math.round(value / 60 / 24) + " " + i18n.tc("general.timeAppendices.d", Math.round(value / 60 / 24));
        default:
            return value;
    }
});

Vue.filter("dateFormat", (value: number | string, format: string) => {
    if (typeof value !== "string") return value;
    // @ts-ignore
    return new TDSDate(value).format(format || "DD-MM-YYYY HH:mm");
});

//  In an older version we got some service workers (empty) registered that broke the cache on Safari.
//  So we are removing all service workers here.
if ("serviceWorker" in navigator) {
    navigator.serviceWorker.getRegistrations().then(function (registrations: readonly ServiceWorkerRegistration[]) {
        if (registrations.length > 0) {
            console.log("[main] Service worker registrations: ", registrations);
            for (const registration of registrations) {
                registration.unregister().then(() => {
                    console.log("[main] Unregistered service worker.", registration);
                });
            }
        }
    });
} else {
    console.warn("[main] Browser does not support service workers!");
}

async function init() {
    new Vue({
        router,
        i18n,
        store,
        render: (h: (component: VueConstructor) => VNode) => h(App)
    }).$mount("#app");
}

init().then(() => console.log("[main] Application started."));
