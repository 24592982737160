import BezierEasing from "bezier-easing";
// import easings from "./easings.ts";
import _ from "./utils";

const easings = {
    ease: [0.25, 0.1, 0.25, 1.0],
    linear: [0.0, 0.0, 1.0, 1.0],
    "ease-in": [0.42, 0.0, 1.0, 1.0],
    "ease-out": [0.0, 0.0, 0.58, 1.0],
    "ease-in-out": [0.42, 0.0, 0.58, 1.0]
};

const abortEvents = [
    "mousedown",
    "wheel",
    "DOMMouseScroll",
    "mousewheel",
    "keyup",
    "touchmove"
];

let defaults = {
    container: "body",
    duration: 500,
    easing: "ease",
    offset: 0,
    force: true,
    cancelable: true,
    onStart: false,
    onDone: false,
    onCancel: false,
    x: false,
    y: true
};

export function setDefaults(options: any) {
    defaults = Object.assign({}, defaults, options);
}

export const scroller = () => {
    let element: any; // element to scroll to
    let container: any; // container to scroll
    let duration: any; // duration of the scrolling
    let easing; // easing to be used when scrolling
    let offset; // offset to be added (subtracted)
    let force; // force scroll, even if element is visible
    let cancelable: any; // indicates if user can cancel the scroll or not.
    let onStart; // callback when scrolling is started
    let onDone: any; // callback when scrolling is done
    let onCancel: any; // callback when scrolling is canceled / aborted
    let x: any; // scroll on x axis
    let y: any; // scroll on y axis

    let initialX: any; // initial X of container
    let targetX: any; // target X of container
    let initialY: any; // initial Y of container
    let targetY: any; // target Y of container
    let diffX: any; // difference
    let diffY: any; // difference

    let abort: any; // is scrolling aborted

    let abortEv: any; // event that aborted scrolling
    const abortFn = (e: any) => {
        if (!cancelable) return;
        abortEv = e;
        abort = true;
    };
    let easingFn: any;

    let timeStart: any; // time when scrolling started
    let timeElapsed; // time elapsed since scrolling started

    let progress; // progress

    function scrollTop(container: any) {
        let scrollTop = container.scrollTop;

        if (container.tagName.toLowerCase() === "body") {
            // in firefox body.scrollTop always returns 0
            // thus if we are trying to get scrollTop on a body tag
            // we need to get it from the documentElement
            scrollTop = scrollTop || document.documentElement.scrollTop;
        }

        return scrollTop;
    }

    function scrollLeft(container: any) {
        let scrollLeft = container.scrollLeft;

        if (container.tagName.toLowerCase() === "body") {
            // in firefox body.scrollLeft always returns 0
            // thus if we are trying to get scrollLeft on a body tag
            // we need to get it from the documentElement
            scrollLeft = scrollLeft || document.documentElement.scrollLeft;
        }

        return scrollLeft;
    }

    function step(timestamp: any) {
        if (abort) return done();
        if (!timeStart) timeStart = timestamp;

        timeElapsed = timestamp - timeStart;

        progress = Math.min(timeElapsed / duration, 1);
        progress = easingFn(progress);

        topLeft(container, initialY + diffY * progress, initialX + diffX * progress);

        timeElapsed < duration ? window.requestAnimationFrame(step) : done();
    }

    function done() {
        if (!abort) topLeft(container, targetY, targetX);
        timeStart = false;

        _.off(container, abortEvents, abortFn);
        if (abort && onCancel) onCancel(abortEv, element);
        if (!abort && onDone) onDone(element);
    }

    function topLeft(element: any, top: any, left: any) {
        if (y) element.scrollTop = top;
        if (x) element.scrollLeft = left;
        if (element.tagName.toLowerCase() === "body") {
            // in firefox body.scrollTop doesn't scroll the page
            // thus if we are trying to scrollTop on a body tag
            // we need to scroll on the documentElement
            if (y) document.documentElement.scrollTop = top;
            if (x) document.documentElement.scrollLeft = left;
        }
    }

    function scrollTo(target: any, _duration: any, options: any = {}) {
        if (typeof _duration === "object") {
            options = _duration;
        } else if (typeof _duration === "number") {
            options.duration = _duration;
        }

        element = _.$(target);

        if (!element) {
            return console.warn(
                "[vue-scrollto warn]: Trying to scroll to an element that is not on the page: " +
          target
            );
        }

        container = _.$(options.container || defaults.container);
        duration = options.duration || defaults.duration;
        easing = options.easing || defaults.easing;
        offset = options.offset ? options.offset : defaults.offset;
        force = options.force ? options.force !== false : defaults.force;
        cancelable = options.cancelable ? options.cancelable !== false : defaults.cancelable;
        onStart = options.onStart || defaults.onStart;
        onDone = options.onDone || defaults.onDone;
        onCancel = options.onCancel || defaults.onCancel;
        x = options.x === undefined ? defaults.x : options.x;
        y = options.y === undefined ? defaults.y : options.y;

        const cumulativeOffsetContainer = _.cumulativeOffset(container);
        const cumulativeOffsetElement = _.cumulativeOffset(element);

        if (typeof offset === "function") {
            offset = offset(element, container);
        }

        initialY = scrollTop(container);
        targetY =
      cumulativeOffsetElement.top - cumulativeOffsetContainer.top + offset;

        initialX = scrollLeft(container);
        targetX =
      cumulativeOffsetElement.left - cumulativeOffsetContainer.left + offset;

        abort = false;

        diffY = targetY - initialY;
        diffX = targetX - initialX;

        if (!force) {
            // When the container is the default (body) we need to use the viewport
            // height, not the entire body height
            const containerHeight =
        container.tagName.toLowerCase() === "body"
            ? document.documentElement.clientHeight || window.innerHeight
            : container.offsetHeight;
            const containerTop = initialY;
            const containerBottom = containerTop + containerHeight;
            const elementTop = targetY - offset;
            const elementBottom = elementTop + element.offsetHeight;
            if (elementTop >= containerTop && elementBottom <= containerBottom) {
                // make sure to call the onDone callback even if there is no need to
                // scroll the container. Fixes #111 (ref #118)
                if (onDone) onDone(element);
                return;
            }
        }

        if (onStart) onStart(element);

        if (!diffY && !diffX) {
            if (onDone) onDone(element);
            return;
        }

        if (typeof easing === "string") {
            easing = easings["ease"];
        }

        easingFn = BezierEasing.apply(BezierEasing, easing);

        _.on(container, abortEvents, abortFn, { passive: true });

        window.requestAnimationFrame(step);

        return () => {
            abortEv = null;
            abort = true;
        };
    }

    return scrollTo;
};

const _scroller = scroller();
export default _scroller;
