var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:{'content-card': !_vm.isMobile}},[_c('div',{class:{'content-card': _vm.isMobile}},[_c('h1',[_vm._v(_vm._s(_vm.$t("pools.title")))]),_c('h2',{staticClass:"subtitle"},[_vm._v(" "+_vm._s(_vm.$t("pools.subtitle"))+" ")]),(_vm.error)?_c('TDSError',{attrs:{"message":_vm.error}}):_vm._e()],1),(_vm.isLoading)?_c('div',{staticClass:"spinner-wrapper"},[_c('TDSSpinner',{attrs:{"size":40,"border-width":4,"color":"#909090"}})],1):[(!_vm.isMobile)?_c('TableList',{staticClass:"mt-6",attrs:{"columns":_vm.columns,"rows":_vm.pools},scopedSlots:_vm._u([{key:"column_pair",fn:function(ref){
var data = ref.data;
return [_c('span',{staticClass:"pair-icons"},[_c('img',{attrs:{"src":require('../../assets/currencies/' + data.pair[0].toLowerCase() + '.svg')}}),_c('img',{attrs:{"src":require('../../assets/currencies/' + data.pair[1].toLowerCase() + '.svg')}})]),_vm._v(" "),_c('b',[_vm._v(_vm._s(data.pair[0])+"/"+_vm._s(data.pair[1]))])]}},{key:"column_liquidity",fn:function(ref){
var data = ref.data;
return [_c('span',{staticClass:"number"},[_vm._v(" "+_vm._s(_vm._f("fiatFormat")(data.liquidity))+" ")])]}},{key:"column_volume",fn:function(ref){
var data = ref.data;
return [_c('span',{staticClass:"number"},[_vm._v(" "+_vm._s(_vm._f("fiatFormat")(data.volume))+" ")])]}},{key:"column_fees",fn:function(ref){
var data = ref.data;
return [_c('span',{staticClass:"number"},[_vm._v(" "+_vm._s(_vm._f("fiatFormat")(data.fees))+" ")])]}},{key:"column_link",fn:function(ref){
var data = ref.data;
return [_c('a',{staticClass:"link-icon",attrs:{"href":data.link,"target":"_blank"}})]}}],null,false,2686547838)}):_c('div',_vm._l((_vm.pools),function(entry,index){return _c('div',{key:index,staticClass:"pool-card"},[_c('div',{staticClass:"pair"},[_c('span',{staticClass:"pair-icons"},[_c('img',{attrs:{"src":require('../../assets/currencies/' + entry.pair[0].toLowerCase() + '.svg')}}),_c('img',{attrs:{"src":require('../../assets/currencies/' + entry.pair[1].toLowerCase() + '.svg')}})]),_vm._v(" "),_c('b',[_vm._v(_vm._s(entry.pair[0])+"/"+_vm._s(entry.pair[1]))])]),_c('div',{staticClass:"infos"},[_c('div',[_c('div',{staticClass:"label"},[_vm._v(" "+_vm._s(_vm.$t("pools.table.liquidity")))]),_c('div',{staticClass:"value"},[_vm._v(" "+_vm._s(_vm._f("fiatFormat")(entry.liquidity)))])]),_c('div',[_c('div',{staticClass:"label"},[_vm._v(" "+_vm._s(_vm.$t("pools.table.volume")))]),_c('div',{staticClass:"value"},[_vm._v(" "+_vm._s(_vm._f("fiatFormat")(entry.volume)))])]),_c('div',[_c('div',{staticClass:"label"},[_vm._v(" "+_vm._s(_vm.$t("pools.table.fees")))]),_c('div',{staticClass:"value"},[_vm._v(" "+_vm._s(_vm._f("fiatFormat")(entry.fees)))])])]),_c('TDSButton',{attrs:{"button-style":"primary-red"},on:{"click":function($event){return _vm.openLink(entry.link)}}},[_c('img',{attrs:{"src":require("../../assets/icons/external-link-white.svg")}}),_vm._v(" "+_vm._s(_vm.$t("pools.table.buttonText"))+" ")])],1)}),0)]],2)}
var staticRenderFns = []

export { render, staticRenderFns }