/*import { Price, CurrencyAmount, Currency, Fraction } from "@uniswap/sdk-core";*/
import BigNumber from "bignumber.js";

export function formatCurrencyAmount(amount: BigNumber, sigFigs: number) {

    if (amount.isLessThan(new BigNumber(0.00001))) {
        return "<0.00001";
    }
    return amount.sd(sigFigs);
}

/*export function formatPrice(price: Price<Currency, Currency> | undefined, sigFigs: number) {
    if (!price) {
        return "-";
    }

    if (parseFloat(price.toFixed(sigFigs)) < 0.0001) {
        return "<0.0001";
    }

    return price.toSignificant(sigFigs);
}*/
