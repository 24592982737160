































import {Component, Prop, Vue} from "vue-property-decorator";
import DefaultNavigationBar from "../partials/DefaultNavigationBar.vue";
import DefaultFooter from "../partials/DefaultFooter.vue";
import DefaultHeader from "@/components/partials/DefaultHeader.vue";
import TDSButton from "@/components/common/TDSButton.vue";
import WalletConnector from "@/components/partials/WalletConnector.vue";

@Component({
    components: {
        WalletConnector,
        TDSButton,
        DefaultHeader,
        DefaultFooter,
        DefaultNavigationBar
    }
})
export default class DefaultLayout extends Vue {
    @Prop({type: Boolean, default: true}) showNav!: boolean;
    @Prop({type: Boolean, default: true}) showFooter!: boolean;

    private isWalletConnected: boolean = false;

    get previousView(): boolean {
        return this.$store.state.previousView;
    }

    get isMobile(): boolean {
        return this.$store.state.isMobile;
    }

    get headerHeight(): string {
        return this.$store.state.headerHeight;
    }

    get showWalletConnector() {
        return this.$route.name !== "Onboarding" && this.$route.name !== "Liquidity Pools" && !this.isWalletConnected;
    }
}
