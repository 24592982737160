















import {Component, Prop, Vue} from "vue-property-decorator";
import TDSDate from "@/util/TDSDate";

@Component
export default class DefaultFooter extends Vue {
    @Prop({type: Boolean, default: false}) darkMode!: boolean;

    get isMobile() {
        return this.$store.state.isMobile;
    }

    get appVersion(): string {
        return process.env.VUE_APP_VERSION + "";
    }

    get gitCommitHash(): string {
        return process.env.VUE_APP_GIT_COMMIT_HASH + "";
    }

    get network(): string {
        return process.env.VUE_APP_ETH_NETWORK + "";
    }

    get currentYear(): string {
        return new TDSDate().format("YYYY");
    }
}
