

































































import {Component, Vue} from "vue-property-decorator";
import TableList from "@/components/common/TableList.vue";
import TDSButton from "@/components/common/TDSButton.vue";
import {fetchPoolChartData, getPools} from "@/util/PoolInfo.ts";
import TDSError from "@/components/common/TDSError.vue";
import TDSSpinner from "@/components/common/TDSSpinner.vue";

@Component({
    components: {TDSButton, TableList, TDSError, TDSSpinner}
})
export default class LiquidityPools extends Vue {
    private error: string = "";
    private isLoading: boolean = false;
    private columns: object = {
        pair: {label: this.$t("pools.table.pair") as string, sortable: true},
        liquidity: {label: this.$t("pools.table.liquidity") as string, align: "left", sortable: true},
        volume: {label: this.$t("pools.table.volume") as string, align: "right", sortable: true},
        fees: {label: this.$t("pools.table.fees") as string, align: "right", sortable: true},
        link: {label: "", width: "50px"}
    }
    private pools: any = [
        {
            pair: ["DENT","ETH"],
            liquidity: 0,
            volume: 0,
            fees: 0,
            link: "https://info.uniswap.org/#/pools/0x8b6ed5fa776f10787f1171bdeea4f3c40974df6e"
        }
    ]

    created() {
        this.fetchPoolData();
    }

    get isMobile(): boolean {
        return this.$store.state.isMobile;
    }

    private async fetchPoolData() {
        this.isLoading = true;
        try {
            const [{address}] = getPools("mainnet"); // pool stats are only available in mainnet
            const {error, data} = await fetchPoolChartData(address);
            if (error || !data) return this.$toastr.error("Could not load data. Please reload the page.");
            console.log(error, data, address);
            this.pools[0].volume = data[0].volumeUSD;
            this.pools[0].fees = data[0].feesUSD;
            this.pools[0].liquidity = data[0].totalValueLockedUSD;
        } catch (e) {
            this.error = "An unexpected error occurred. Please try again later.";
        }
        this.isLoading = false;
    }

    private openLink(link: string) {
        window.open(link);
    }
}
