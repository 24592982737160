import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import weekOfYear from "dayjs/plugin/weekOfYear";
import gql from "graphql-tag";
import {PoolChartEntry} from "@/util/PoolChartEntry.ts";
import {ApolloClient, InMemoryCache} from "@apollo/client";

// format dayjs with the libraries that we need
dayjs.extend(utc);
dayjs.extend(weekOfYear);
const ONE_DAY_UNIX = 24 * 60 * 60;

export interface PoolInfo {
    address: string;
}

export const getPools = function (network?: string): PoolInfo[] {
    let address;
    if ((network ?? process.env.VUE_APP_ETH_NETWORK) === "ropsten") {
        address = "0x2FaE971C3d99E04cb289E77CE35F0c9df456604A"; // ETH-DENT 1% Pool on Ropsten
        // address = "0x9cf9A41136802570FFE0415904A99ba19eb9C0ca"; // ETH-DENT 0.3% Pool on Ropsten
    } else if ((network ?? process.env.VUE_APP_ETH_NETWORK) === "mainnet") {
        address = "0x8b6ed5fa776f10787f1171bdeea4f3c40974df6e"; // ETH-DENT Pool on Mainnet
    } else {
        throw new Error("Unknown network in 'process.env.VUE_APP_ETH_NETWORK'.");
    }
    console.log("[PoolInfo] Pool address: ", address);
    return [{address}];
};

export const getApiUrl = function (network?: string): string {
    if ((network ?? process.env.VUE_APP_ETH_NETWORK) === "ropsten") {
        return "https://api.thegraph.com/subgraphs/name/ianlapham/uniswap-v3-ropsten";
    } else if ((network ?? process.env.VUE_APP_ETH_NETWORK) === "mainnet") {
        return "https://api.thegraph.com/subgraphs/name/uniswap/uniswap-v3";
    } else {
        throw new Error("Unknown network in 'process.env.VUE_APP_ETH_NETWORK'.");
    }
};

export const client = new ApolloClient({
    uri: getApiUrl("mainnet"), // stats are only available on mainnet...
    cache: new InMemoryCache({
        typePolicies: {
            Token: {
                // Singleton types that have no identifying field can use an empty
                // array for their keyFields.
                keyFields: false
            },
            Pool: {
                // Singleton types that have no identifying field can use an empty
                // array for their keyFields.
                keyFields: false
            }
        }
    }),
    queryDeduplication: true,
    defaultOptions: {
        watchQuery: {
            fetchPolicy: "no-cache"
        },
        query: {
            fetchPolicy: "no-cache",
            errorPolicy: "all"
        }
    }
});

const POOL_CHART = gql`
  query poolDayDatas($startTime: Int!, $skip: Int!, $address: Bytes!) {
    poolDayDatas(
      first: 1000
      skip: $skip
      where: { pool: $address, date_gt: $startTime }
      orderBy: date
      orderDirection: asc
      subgraphError: allow
    ) {
      date
      volumeUSD
      tvlUSD
      feesUSD
    }
  }
`;

interface ChartResults {
    poolDayDatas: {
        date: number;
        volumeUSD: string;
        tvlUSD: string;
        feesUSD: string;
    }[];
}

export async function fetchPoolChartData(address: string): Promise<{
    error?: Error;
    data?: Array<{
        date: number;
        feesUSD: number;
        totalValueLockedUSD: number;
        volumeUSD: number;
    }>;
}> {
    let data: {
        date: number;
        volumeUSD: string;
        tvlUSD: string;
        feesUSD: string;
    }[] = [];
    const startTimestamp = dayjs.utc().unix() - (ONE_DAY_UNIX);
    const endTimestamp = dayjs.utc().unix();

    let error = new Error("Could not load pool info.");
    let skip = 0;
    let allFound = false;

    try {
        while (!allFound) {
            const {data: chartResData, error, loading} = await client.query<ChartResults>({
                query: POOL_CHART,
                variables: {
                    address: address,
                    startTime: startTimestamp,
                    skip
                },
                fetchPolicy: "cache-first"
            });
            if (!loading) {
                skip += 1000;
                if (chartResData.poolDayDatas.length < 1000 || error) {
                    allFound = true;
                }
                if (chartResData) {
                    data = data.concat(chartResData.poolDayDatas);
                }
            }
        }
    } catch (e) {
        error = e;
    }

    if (data) {
        // @ts-ignore
        const formattedExisting = data.reduce((accum: { [date: number]: PoolChartEntry }, dayData: any) => {
            const roundedDate = parseInt((dayData.date / ONE_DAY_UNIX).toFixed(0));
            accum[roundedDate] = {
                date: dayData.date,
                volumeUSD: parseFloat(dayData.volumeUSD),
                totalValueLockedUSD: parseFloat(dayData.tvlUSD),
                feesUSD: parseFloat(dayData.feesUSD)
            };
            return accum;
        }, {});

        const firstEntry = formattedExisting[parseInt(Object.keys(formattedExisting)[0])];

        // fill in empty days ( there will be no day datas if no trades made that day )
        let timestamp = firstEntry?.date ?? startTimestamp;
        let latestTvl = firstEntry?.totalValueLockedUSD ?? 0;
        while (timestamp < endTimestamp - ONE_DAY_UNIX) {
            const nextDay = timestamp + ONE_DAY_UNIX;
            const currentDayIndex = parseInt((nextDay / ONE_DAY_UNIX).toFixed(0));
            if (!Object.keys(formattedExisting).includes(currentDayIndex.toString())) {
                formattedExisting[currentDayIndex] = {
                    date: nextDay,
                    volumeUSD: 0,
                    totalValueLockedUSD: latestTvl,
                    feesUSD: 0
                };
            } else {
                latestTvl = formattedExisting[currentDayIndex].totalValueLockedUSD;
            }
            timestamp = nextDay;
        }

        const dateMap = Object.keys(formattedExisting).map((key: string) => {
            return formattedExisting[parseInt(key)];
        });

        return {
            data: dateMap,
            error: undefined
        };
    } else {
        return {
            data: undefined,
            error
        };
    }
}
