// https://github.com/WICG/EventListenerOptions/blob/gh-pages/explainer.md#feature-detection
let supportsPassive = false;
try {
    const opts: any = Object.defineProperty({}, "passive", {
        get: function() {
            supportsPassive = true;
            return true;
        }
    });
    // @ts-ignore
    window.addEventListener("test", null, opts);
} catch (e) {
    console.log("error on defining properties");
}

export default {
    $(selector: string) {
        if (typeof selector !== "string") {
            return selector;
        }
        return document.querySelector(selector);
    },
    on(element: any, events: any, handler: any, opts: any = { passive: false }) {
        if (!(events instanceof Array)) {
            events = [events];
        }
        for (let i = 0; i < events.length; i++) {
            element.addEventListener(
                events[i],
                handler,
                supportsPassive ? opts : false
            );
        }
    },
    off(element: any, events: any, handler: any) {
        if (!(events instanceof Array)) {
            events = [events];
        }
        for (let i = 0; i < events.length; i++) {
            element.removeEventListener(events[i], handler);
        }
    },
    cumulativeOffset(element: any) {
        let top = 0;
        let left = 0;

        do {
            top += element.offsetTop || 0;
            left += element.offsetLeft || 0;
            element = element.offsetParent;
        } while (element);

        return {
            top: top,
            left: left
        };
    }
};
