



















































import {Component, Vue} from "vue-property-decorator";
import TDSButton from "@/components/common/TDSButton.vue";
import TDSModal from "@/components/common/TDSModal/TDSModal.vue";
import detectEthereumProvider from "@metamask/detect-provider";
import Web3 from "web3";
import WalletConnect from "@walletconnect/client";
import QRCodeModal from "@walletconnect/qrcode-modal";
import TDSSpinner from "@/components/common/TDSSpinner.vue";
import {IAppState} from "@/interfaces/IAppState";
import {ChainId} from "@uniswap/sdk";

@Component({
    components: {TDSButton, TDSModal, TDSSpinner}
})
export default class WalletConnector extends Vue {
    private isLoadingWallet: boolean = false;
    private walletModalOpen: boolean = false;
    private isMetaMaskInstalled: boolean = false;
    private accounts: any = [];

    private tcUrl: string = "https://www.dentwireless.com/tc";

    private INITIAL_STATE: IAppState = {
        connector: null,
        fetching: false,
        connected: false,
        chainId: 1,
        showModal: false,
        pendingRequest: false,
        uri: "",
        accounts: [],
        address: "",
        result: null,
        assets: []
    };
    public state: IAppState = {
        ...this.INITIAL_STATE
    };

    get isMobile(): boolean {
        return this.$store.state.isMobile;
    }

    created() {
        this.init();
    }

    async init() {
        if (!this.isMobile) {
            (window as any).web3 = new Web3((window as any).ethereum);
            this.accounts = await (window as any).web3.eth.getAccounts();
            if (this.accounts && this.accounts.length > 0) await this.connectWallet();
        } else {
            const connector = new WalletConnect({
                bridge: "https://bridge.walletconnect.org", // Required
                qrcodeModal: QRCodeModal
            });
            if (connector.connected) {
                this.$emit("walletConnected", true);
            }
        }
    }

    get chainId(): number {
        switch (process.env.VUE_APP_ETH_NETWORK) {
            case "mainnet":
                return ChainId.MAINNET;
            case "ropsten":
                return ChainId.ROPSTEN;
            default:
                throw new Error("Unknown network in 'process.env.VUE_APP_ETH_NETWORK': " + process.env.VUE_APP_ETH_NETWORK);
        }
    }

    async expectCorrectNetwork() {
        const chainId: string = (window as any).ethereum.networkVersion;
        if (chainId !== this.chainId + "") {
            throw new Error("WRONG_NETWORK");
        }
    }

    async connectWallet() {
        this.isLoadingWallet = true;
        const provider = await detectEthereumProvider();
        if (provider) {
            try {
                const ethEnabled = async () => {
                    if ((window as any).ethereum) {
                        await (window as any).ethereum.request({method: "eth_requestAccounts"});
                        await this.expectCorrectNetwork();
                        this.$emit("walletConnected", true);
                    } else {
                        this.$emit("walletConnected", false);
                    }
                };
                await ethEnabled();
                this.accounts = await (window as any).web3.eth.getAccounts();  //  get user's account address
                this.isMetaMaskInstalled = true;
                console.log("Ethereum successfully detected!" +
                    "\n\tNetwork version: " +
                    (window as any).ethereum.networkVersion + "" +
                    "\n\tAccount address: " +
                    this.accounts[0] +
                    "\n\tBalance:" +
                    await (window as any).web3.eth.getBalance(this.accounts[0]));
            } catch (e) {
                console.log("[WalletConnector] Error on connecting: ", e);
                if (e.code === -32002) {
                    this.$toastr.error("Waiting for pending request to MetaMask. Please open your MetaMask to approve the request.");
                } else if (e.message === "WRONG_NETWORK") {
                    this.$toastr.error("Your MetaMask browser plugin is connected to the wrong Ethereum network. Please use: '" + process.env.VUE_APP_ETH_NETWORK + "'.");
                } else {
                    this.$toastr.error("Sorry! An unexpected error occurred. Please reload page and try again.");
                }
            }
        } else {
            console.error("[WalletConnector] MetaMask not installed.");
            this.$toastr.error("In order to use DENT Swap, please install the MetaMask plugin into your Browser.");
        }
        this.isLoadingWallet = false;
    }

    async connectMobileWallet() {
        const connector = new WalletConnect({
            bridge: "https://bridge.walletconnect.org", // Required
            qrcodeModal: QRCodeModal
        });

        // await this.setState({ connector });

        // check if already connected
        if (!connector.connected) {
            // create new session
            await connector.createSession();
        }

        connector.on("session_update", async (error: any, payload: any) => {
            // console.log(`connector.on("session_update")`);

            if (error) {
                throw error;
            }

            const {chainId, accounts} = payload.params[0];
            this.onSessionUpdate(accounts, chainId);
        });
        connector.on("connect", (error: any, payload: any) => {
            // console.log(`connector.on("connect")`);

            if (error) {
                throw error;
            }
            this.onConnect(payload);
        });
        connector.on("disconnect", (error: any, payload: any) => {
            // console.log(`connector.on("disconnect")`);

            if (error) {
                throw error;
            }

            // this.onDisconnect();
        });
    }

    public onSessionUpdate: any = async (accounts: string[], chainId: number) => {
        const address = accounts[0];

        // await this.setState({ chainId, accounts, address });
        // await this.getAccountAssets();
    };

    public onConnect: any = async (payload: any) => {
        const {chainId, accounts} = payload.params[0];
        const address = accounts[0];
        this.$emit("walletConnected", true);
        console.log("Mobile Wallet Connected!");
    }
}
