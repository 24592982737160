


















import {Component, Prop, Vue} from "vue-property-decorator";
import InputField from "../common/InputField.vue";
import BigNumber from "bignumber.js";

@Component({
    components: {InputField}
})
export default class CurrencyInputfield extends Vue {
    @Prop({required: true}) value!: BigNumber;
    @Prop({type: String}) label!: string;
    @Prop({type: Boolean, default: false}) autofocus!: boolean;
    @Prop({type: String}) currencyName!: string;
    @Prop({type: Boolean, default: false}) inactive!: boolean;
    @Prop({type: Number}) maxDp!: number;

    private id: string = Math.random().toString(36).substr(2, 5);
    private inputSelected: boolean = false;
    private lastValue: string = "";
    private fmt: object = {
        prefix: "",
        decimalSeparator: ".",
        groupSeparator: ",",
        groupSize: 3,
        secondaryGroupSize: 0,
        fractionGroupSeparator: " ",
        fractionGroupSize: 0,
        suffix: ""
    };

    get _value(): string {
        return this.getFormat(this.value);
    }

    get currencyMargin(): number {
        const full = this.getFormat(this.value);
        let m = 0;
        m += 13;
        for(let i = 0; i < full.length; i++) {
            if(full.substr(i,1) === "1") m += 6.2;
            else if(full.substr(i,1) === "2") m += 8.7;
            else if(full.substr(i,1) === "3" || full.substr(i,1) === "9") m += 8.8;
            else if(full.substr(i,1) === "4") m += 9.3;
            else if(full.substr(i,1) === "5" || full.substr(i,1) === "6") m += 9.1;
            else if(full.substr(i,1) === "7") m += 8.2;
            else if(full.substr(i,1) === "8") m += 10.1;
            else if(full.substr(i,1) === "0") m += 11.5;
            else if(full.substr(i,1) === ",") m += 5;
            else if(full.substr(i,1) === ".") m += 10;
        }
        return m;
    }

    getFormat(value: BigNumber) {
        let returnVal = value.toFormat(this.fmt);
        if(value.isZero() && !this.inputSelected) returnVal = value.toFormat(2, this.fmt);
        const split = value.toFormat(this.fmt).split(".");
        if(split.length > 1 &&  this.maxDp && split[1].length > this.maxDp) returnVal = value.toFormat(this.maxDp, this.fmt);
        return returnVal;
    }

    private onBlur(e: Event) {
        this.inputSelected = false;
        let value: string = (e.target as HTMLTextAreaElement)?.value;
        if(!value) value = "0";
        if(value.charAt(0) === ".") value = "0" + value;
        this.$emit("update:value", new BigNumber(value.replace(/,/g,"")));
    }
    private onFocus(e: Event) {
        this.inputSelected = true;
        this.$nextTick(() => {
            const position = (e.target as HTMLInputElement).selectionStart;
            (e.target as HTMLTextAreaElement).value = (e.target as HTMLTextAreaElement).value.replace(/,/g,"");
            (e.target as HTMLInputElement).selectionEnd = position;
            this.lastValue = (e.target as HTMLTextAreaElement).value;
        });
    }
    private onKeyDown() {
        //TODO
    }

    private dataChanged(e: InputEvent) {
        const value: string = (e.target as HTMLTextAreaElement)?.value;
        if(isNaN(Number(e.data)) && e.data !== "." && e.data !== ",") return (e.target as HTMLTextAreaElement).value = this.lastValue;
        if(value.split(".").length > 2 && e.data === ".") return (e.target as HTMLTextAreaElement).value = this.lastValue;
        this.lastValue = value;
    }
}
